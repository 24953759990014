export const environment = {
  production: true,
  name: 'production',
  backendUrl: 'https://ops.cartken.com/api',
  mlBackendUrl: 'https://ops.cartken.com/ml/api',
  socketUrl: 'wss://ops.cartken.com/api',
  googleMapsApiKey: 'AIzaSyDFLb7J19fsCdlMEErap2EGAC32ZgIzqWw',
  mapboxApiKey:
    'pk.eyJ1Ijoiam9uYXMtd2l0dCIsImEiOiJjazJhN2FmYWMycWprM25ucnIyZ3FraWg2In0.oXIOU0KMN4yLWANhXodltg',
  siteSwitch: {
    name: 'Staging',
    url: 'https://staging-ops.cartken.com',
  },
  sentry: {
    dsn: 'https://1d294c4164714b6aa072bcbbd806405e@o4505049490194432.ingest.sentry.io/4505049522044928', // TODO: Populate this from .env files
    tunnel: undefined, // Use sentry.io directly.
    enabled: true,
    errors: true,
    tracing: 0,
    replay: false,
    replaySessions: 0.01,
    replayErrors: 0.05,
  },
};
